import React from 'react';
import PropTypes from 'prop-types';
import Card from '@shoreag/base/Card';
import formatDateTimePretty from '@shoreag/helpers/format-date-time-pretty';
import { get } from 'lodash';
import Box from '@shoreag/base/Box';
import KeyValuePairs from '../../KeyValuePairs';
import PipelineTypeLabel from '../../PipelineTypeLabel';
import PipelineInformation from '../PipelineInformation';
import enhanceKeyValuePairEntries from '../../../utilities/enhance-key-value-pairs';
import config from '../../../config.json';

const PipelineOverviewTab = ({ pipelineData }) => {
  const pipeline = get(pipelineData, 'pipeline', {});
  const allStepDefinitions = get(pipelineData, 'allStepDefinitions', {});
  return (
    <>
      <Card>
        <h2>Overview</h2>
        <KeyValuePairs
          /* eslint-disable sort-keys */
          pairs={Object.entries({
            type: <PipelineTypeLabel pipelineType={pipeline?.pipelineType} />,
            description: pipeline?.description,
            lastUpdated: formatDateTimePretty(pipeline?.lastUpdated),
            executedBy: pipeline?.executedBy,
            partner: pipeline?.partner,
            autoTrigger: pipeline?.autoTrigger || '',
            copiedFrom: pipeline?.copiedFrom,
            tags: pipeline?.tags || [],
          }).map(enhanceKeyValuePairEntries)}
          /* eslint-enable sort-keys */
        />
      </Card>
      {!!pipeline?.steps?.length > 0 && (
        <>
          <Box as="h2" sx={{ mb: 4, mt: 6 }}>
            {config.nomenclature.Steps}
          </Box>
          <PipelineInformation
            allStepDefinitions={allStepDefinitions}
            inputs={pipeline?.inputs}
            steps={pipeline?.steps}
          />
        </>
      )}
    </>
  );
};

PipelineOverviewTab.propTypes = {
  pipelineData: PropTypes.shape({
    pipeline: PropTypes.shape({}),
  }).isRequired,
  // pipelineId: PropTypes.string,
};

PipelineOverviewTab.defaultProps = {
  // pipelineId: null,
};

export default PipelineOverviewTab;
