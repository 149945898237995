import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import get from 'lodash/get';
import { TooltipTrigger } from '@shoreag/base/Tooltip';
import { rgba } from 'polished';
import { ThemeContext } from 'styled-components';
import getExecutionResultPillDetails from '../../utilities/get-execution-result-pill-details';

const parseJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch {
    return {};
  }
};

const PipelineExecutionResultPills = ({ pillSx, results, steps }) => {
  const theme = useContext(ThemeContext);

  const getBackgroundColor = useMemo(
    () => (bgColor) =>
      bgColor === 'gray1' || bgColor === 'grays.3'
        ? bgColor
        : rgba(theme.colors[bgColor], 0.3),
    [theme.colors]
  );

  return (
    <Box display="flex">
      {results.map((result, index) => {
        const { bgColor, status, stepName } = getExecutionResultPillDetails(
          result
        );

        const defaultStyled = {
          color:
            bgColor === 'gray1' || bgColor === 'grays.3' ? 'grays.4' : bgColor,
          fontWeight: 700,
          textAlign: 'center',
        };

        const parsedOutputs = parseJSON(result?.outputs);
        const successCount = get(parsedOutputs, 'success', 0);
        const displayName = get(
          steps.find((step) => step.name === stepName),
          'displayName',
          stepName
        );

        return (
          <TooltipTrigger
            key={result.stepName || index}
            data-tip={status}
            data-tip-disable={!status}
            id="tooltip"
          >
            <Box
              sx={{
                alignItems: 'center',
                bg: getBackgroundColor(bgColor),
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                fontSize: 2,
                height: '100%',
                justifyContent: 'center',
                mr: 3,
                px: 4,
                py: 2,
                ...pillSx,
              }}
            >
              <Box sx={{ mb: 1, ...defaultStyled }}>{successCount}</Box>
              <Box sx={{ ...defaultStyled }}>{displayName}</Box>
            </Box>
          </TooltipTrigger>
        );
      })}
    </Box>
  );
};

PipelineExecutionResultPills.propTypes = {
  pillSx: PropTypes.shape({}),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      inputs: PropTypes.string,
      outputs: PropTypes.string,
    })
  ).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

PipelineExecutionResultPills.defaultProps = {
  pillSx: {},
  steps: [],
};

export default PipelineExecutionResultPills;
