import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '@shoreag/validations';
import { useMutation, useQuery } from 'react-apollo';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import Tabs from '@shoreag/base/Tabs';
import ButtonGroups from '../ButtonGroups';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import ToolbarButton from '../ToolbarButton';
import config from '../../config.json';
import formatUuid from '../../utilities/format-uuid';
import isPermitted from '../../utilities/is-permitted';
import parseUuid from '../../utilities/parse-uuid';
import pipelineDetailsPageQuery from '../../graphql/queries/pipeline-details-page.gql';
import deactivatedPipelineMutation from '../../graphql/mutations/activate-deactivate-pipeline.gql';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import useSnackbar from '../../utilities/use-snackbar';
import PipelineEditHandler from '../PipelineEditHandler';
import PipelineOverviewTab from './PipelineOverviewTab';
import PipelineExecutionsTab from './PipelineExecutionsTab';

const PipelineDetailsPage = ({ location, pipelineId, navigate }) => {
  const { user } = useContext(AuthContext);
  const { code, version } = parseUuid(pipelineId);
  const [pipelineData, setPipelineData] = useState(null);
  const [pipelineDeactived, setPipelineDeactived] = useState(false);
  const [activeDeactiveLoading, setActiveDeactiveLoading] = useState(false);
  const [deactivatedPipeline] = useMutation(deactivatedPipelineMutation);
  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  const steps = get(pipelineData, 'pipeline.steps', []);

  const { data, loading } = useQuery(pipelineDetailsPageQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: pipelineId,
      pipelineCode: code,
      searchBody: JSON.stringify({
        getPipeline: true,
        steps,
      }),
    },
  });

  useEffect(() => {
    if (!loading) {
      setPipelineData(data);
      const isDiactivated = get(data, 'pipeline.deactivated', false);
      setPipelineDeactived(isDiactivated);
    }
  }, [data, loading]);

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const hasExecutePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.X,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const pageTitle = get(pipelineData, 'pipeline.displayName', '');
  const hasSteps = !!get(pipelineData, 'pipeline.steps', []).length;
  const allowExecution = hasExecutePermission && !pipelineDeactived && hasSteps;

  let statusMessage;
  if (activeDeactiveLoading) {
    statusMessage = 'Loading..';
  } else {
    statusMessage = pipelineDeactived ? 'Disabled' : 'Active';
  }

  let tabs = [];

  if (data) {
    tabs = [
      {
        label: 'Overview',
        render: () => <PipelineOverviewTab pipelineData={pipelineData} />,
        value: 'overview',
      },
      {
        label: 'Executions',
        render: () => (
          <PipelineExecutionsTab
            location={location}
            pipelineDeactived={pipelineDeactived}
            pipelineId={pipelineId}
          />
        ),
        value: 'executions',
      },
    ];
  }
  return (
    <Route
      header={{
        icon: 'workflow',
        leftContainer: pipelineData && (
          <FinalForm
            initialValues={{
              deactivated: !pipelineDeactived,
              versionNumber: version,
            }}
            onSubmit={noop}
            render={({ handleSubmit }) => (
              <Box
                as="form"
                fontSize={2}
                ml={4}
                onSubmit={handleSubmit}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 2,
                  ml: 4,
                }}
              >
                <Field
                  component={Dropdown}
                  label={`${config.nomenclature.Pipeline} Version`}
                  labelSx={{ display: 'none' }}
                  name="versionNumber"
                  onChange={(version) => {
                    const param = formatUuid('pipeline', code, version);
                    const options = { replace: true };

                    setPipelineData(null);

                    navigate(
                      `/${config.nomenclature.pipelines}/${param}${
                        location.search || ''
                      }`,
                      options
                    );
                  }}
                  options={get(pipelineData, 'allPipelineVersion', [])
                    .map((value) => ({
                      label: `Version ${value}`,
                      value,
                    }))
                    .reverse()}
                  validate={required}
                  wrapperSx={{ width: '200px' }}
                />
                <Field
                  component={ToggleSwitch}
                  disabled={activeDeactiveLoading || loading}
                  errorSx={{
                    ml: 4,
                    mt: 0,
                  }}
                  label={statusMessage}
                  labelSx={{
                    color: pipelineDeactived ? 'grays.3' : 'accent',
                    ml: 0,
                    mr: 4,
                    order: 'unset',
                    textTransform: 'unset',
                  }}
                  name="deactivated"
                  onClick={async () => {
                    setActiveDeactiveLoading(true);
                    const isDeactivatedRequested = !pipelineDeactived;
                    try {
                      const result = await deactivatedPipeline({
                        variables: {
                          deactivated: !pipelineDeactived,
                          id: pipelineId,
                        },
                      });
                      const { deactivated, id } = get(
                        result,
                        'data.activateDeactivatePipeline',
                        null
                      );
                      if (id) {
                        setPipelineDeactived(deactivated);
                        setSnack(
                          <Box sx={{ textAlign: 'center' }}>
                            Workflow <b>{pageTitle}</b> is{' '}
                            {isDeactivatedRequested ? (
                              <Box as="span" sx={{ color: 'error' }}>
                                deactivated.
                              </Box>
                            ) : (
                              <Box as="span" sx={{ color: 'success' }}>
                                activated.
                              </Box>
                            )}
                          </Box>
                        );
                      }
                    } catch (e) {
                      setErrorSnack(
                        <Box sx={{ textAlign: 'center' }}>
                          Workflow <b>{pageTitle}</b>{' '}
                          {isDeactivatedRequested
                            ? 'deactivation'
                            : 'activation'}{' '}
                          failed, try again later.
                        </Box>
                      );
                    } finally {
                      setActiveDeactiveLoading(false);
                    }
                  }}
                  type="checkbox"
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: 4,
                    mt: 0,
                    width: 'auto',
                  }}
                />
              </Box>
            )}
          />
        ),
        rightContainer: pipelineData && (
          <ButtonGroups>
            {hasWritePermission && (
              <PipelineEditHandler
                pipelineData={{
                  allStepDefinitions: pipelineData.allStepDefinitions,
                  pipeline: pipelineData.pipeline,
                }}
              />
            )}
            {allowExecution && (
              <ToolbarButton
                active
                icon="clock"
                label="Execute"
                link={`/${config.nomenclature.pipelines}/${pipelineId}/schedule`}
              />
            )}
          </ButtonGroups>
        ),
        title: pageTitle,
        type: config.nomenclature.Pipeline,
      }}
      isPrivate
    >
      {loading && !data && <Spinner />}
      {!loading && !data && <NotFoundPage />}
      {!!data && (
        <Tabs
          defaultTab="overview"
          label="Section"
          name="section"
          tabs={tabs}
          type="tabs"
        />
      )}
    </Route>
  );
};

PipelineDetailsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  pipelineId: PropTypes.string,
};

PipelineDetailsPage.defaultProps = {
  pipelineId: null,
};

export default PipelineDetailsPage;
