import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { get, uniqBy, min } from 'lodash';
import { useQuery } from 'react-apollo';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import Spinner from '@shoreag/base/Spinner';
import Table from '@shoreag/base/Table';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import Button from '@shoreag/base/Button';
import generateRedirectPath from '@shoreag/helpers/generate-redirect-path';
import { TooltipBox } from '@shoreag/base/Tooltip';
import TableIcon from '../../TableIcon';
import ButtonGroups from '../../ButtonGroups';
import PipelineExecutionResultPills from '../../PipelineExecutionResultPills';
import TableWrapper from '../../TableWrapper';
import pipelineQuery from '../../../graphql/queries/pipeline.gql';

const INITIAL_FETCH_LIMIT = 3;
const MAX_AUTO_FETCH = 10;
const FETCH_TYPE = {
  LATEST: 'latest',
  OLDER: 'older',
};

const PipelineExecutionsTab = ({ location, pipelineId, pipelineDeactived }) => {
  const [executions, setExecutions] = useState([]);
  const [executionOffset, setExecutionOffset] = useState('');
  const [fetchType, setFetchType] = useState(FETCH_TYPE.OLDER);
  const [pageCriteria, setPageCriteria] = useState({
    [FETCH_TYPE.LATEST]: {
      getExecutions: true,
      getPipeline: true,
      offset: 0,
      pageSize: INITIAL_FETCH_LIMIT,
      sortByDesc: 0,
    },
    [FETCH_TYPE.OLDER]: {
      getExecutions: true,
      getPipeline: true,
      offset: 0,
      pageSize: INITIAL_FETCH_LIMIT,
      sortByDesc: 0,
    },
  });

  const hasFetchedInitialData = useRef(false);

  const queryVariables = {
    id: pipelineId,
    searchBody: JSON.stringify(pageCriteria[fetchType]),
  };

  const { data, loading } = useQuery(pipelineQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (newData) => {
      if (!newData?.pipeline) return;
      if (newData.pipeline.executions) {
        setExecutions((prevExecutions) => {
          const newExecutions =
            fetchType === FETCH_TYPE.OLDER
              ? [...prevExecutions, ...newData.pipeline.executions]
              : [...newData.pipeline.executions, ...prevExecutions];
          return uniqBy(newExecutions, 'id');
        });
      }

      setExecutionOffset((prevOffset) =>
        newData.pipeline.executionOffset !== undefined
          ? newData.pipeline.executionOffset
          : prevOffset
      );
    },
    pollInterval: fetchType === FETCH_TYPE.OLDER ? undefined : 5000,
    variables: queryVariables,
  });

  useEffect(() => {
    if (!hasFetchedInitialData.current && executions.length === 0) {
      hasFetchedInitialData.current = true;
      loadMore();
    }
  }, [executions.length, loadMore]);

  useEffect(() => {
    if (executionOffset) {
      const maxFetchLimit = min([MAX_AUTO_FETCH, executionOffset]);
      if (executions.length < maxFetchLimit) {
        loadMore();
      } else {
        setFetchType(FETCH_TYPE.LATEST);
      }
    }
  }, [executionOffset, executions.length, loadMore]);

  const loadMore = useCallback(() => {
    if (executions.length < executionOffset) {
      setPageCriteria((prev) => {
        let { pageSize } = prev[FETCH_TYPE.OLDER];
        if (executions.length < MAX_AUTO_FETCH) {
          pageSize = Math.min(
            INITIAL_FETCH_LIMIT,
            pageSize,
            MAX_AUTO_FETCH - executions.length
          );
        }
        return {
          ...prev,
          [FETCH_TYPE.OLDER]: {
            ...prev[FETCH_TYPE.OLDER],
            offset: prev[FETCH_TYPE.OLDER].offset + pageSize,
            sortByDesc: 1,
          },
        };
      });
    }
  }, [executionOffset, executions.length]);

  const steps = useMemo(() => get(data, 'pipeline.steps', []), [data]);
  const showLoadMore = executions.length < executionOffset;
  const rows = useMemo(
    () =>
      executions.map((execution) => {
        const { executionDate, results, executionNumber } = execution;
        const inputDetails = JSON.parse(results[0]?.inputDetails || '{}');

        return [
          execution.executionNumber,
          formatDateTimeConcise(executionDate),
          get(
            inputDetails.dataset || inputDetails.sourceDataset,
            'name',
            'Not Applicable'
          ),
          results[0]?.outputs
            ? get(JSON.parse(results[0].outputs), 'total_records', 0)
            : 0,
          <PipelineExecutionResultPills results={results} steps={steps} />,
          <ButtonGroups sx={{ justifyContent: 'flex-end' }}>
            <Button
              link={`${location.pathname}/execution/${executionNumber}`}
              simple
            >
              <TableIcon svg="view" title="View" />
            </Button>
            {!pipelineDeactived && (
              <Button
                link={generateRedirectPath({
                  pathname: `${location.pathname}/schedule`,
                  queryParams: {
                    repeat: Number.parseInt(executionNumber, 10),
                    ...(pageCriteria[fetchType].offset
                      ? {
                          offset: Number.parseInt(
                            pageCriteria[fetchType].offset,
                            10
                          ),
                        }
                      : {}),
                  },
                })}
                simple
              >
                <TableIcon svg="repeat" title="Execute Again" />
              </Button>
            )}
          </ButtonGroups>,
        ];
      }),
    [
      executions,
      location.pathname,
      pageCriteria,
      fetchType,
      pipelineDeactived,
      steps,
    ]
  );

  return (
    <Card mt={5}>
      <Box as="h2" mt="-0.75rem">
        Executions
      </Box>
      <TableWrapper>
        <Table
          header={[
            '#',
            'Started On',
            'Dataset',
            'Total Records',
            'Results',
            '',
          ]}
          isLoading={executions.length === 0 && loading}
          rows={rows}
        />
      </TableWrapper>
      <TooltipBox id="tooltip" />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {loading && executions.length > 0 && fetchType === FETCH_TYPE.OLDER ? (
          <Spinner py="md" />
        ) : (
          <>
            {showLoadMore && (
              <Button
                mt={3}
                onClick={() => {
                  setFetchType(FETCH_TYPE.OLDER);
                  loadMore();
                }}
                sx={{ '&:hover': { color: 'primary' }, color: 'border' }}
                variant="buttons.link"
              >
                Load more &gt;&gt;
              </Button>
            )}
          </>
        )}
      </Box>
    </Card>
  );
};

PipelineExecutionsTab.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  pipelineDeactived: PropTypes.bool,
  pipelineId: PropTypes.string.isRequired,
};

PipelineExecutionsTab.defaultProps = {
  pipelineDeactived: false,
};

export default PipelineExecutionsTab;
